import React, {useState} from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import {postMethod, putMethod} from '../helper/api';
import {navigate} from "gatsby";
import {notify} from 'react-notify-toast';

const Review = ({ location, userDetails }) => {

    const [rating,setRating] = useState(null); 
    const [feedback,setFeedback] = useState(null); 

    //console.log(userDetails);
    async function postFeedback(){

        if(!rating ){notify.show("Select Rating","error"); return; }
        if(!feedback ){ notify.show("Enter Feedback","error"); return; }
	    let postedBy =  userDetails.id;
        let postedFor = userDetails.id===location.state.booking.booked_by.id?location.state.booking.faculty.id:location.state.booking.booked_by.id;
        let resp = await postMethod('/reviews',{booking:location.state.booking.id, rating: rating, feedback:feedback, postedBy: postedBy, postedFor: postedFor});
        if(resp.error){
            notify.show("Error: ", resp.error);
        }else{
            let update = {is_student_reviewed: true};
            if(userDetails.user_role==='faculty') { update = {is_tutor_reviewed: true}; }
            await putMethod(`/bookings/${location.state.booking.id}`,update);
            setTimeout(()=>notify.show("Review posted successfully","success"),100);
            navigate('/dashboard?v='+window.localStorage.getItem('appVersion'))          
        }

    }

    if(!location.state){
        location.state={}
    }

    return (
        <Layout title="Review">
            <Seo title="Review" />
            <div className="has-fixed-btn">
                <div className="container">
                    <div className="date-card-section">
                        <Booking booking={location.state.booking} />
                    </div>
                    <h5>Rating</h5>
                    <form className="cancelClass mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="radio" onClick={()=>setRating(5)}/>
                            <label  className="form-check-label">Excellent</label>
                            <div className="rating">
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                            </div>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="radio" onClick={()=>setRating(4)}/>
                            <label  className="form-check-label">Good</label>
                            <div className="rating">
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                            </div>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="radio"  onClick={()=>setRating(3)}/>
                            <label  className="form-check-label">Average</label>
                            <div className="rating">
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                            </div>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="radio" onClick={()=>setRating(2)}/>
                            <label className="form-check-label">Poor</label>
                            <div className="rating">
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                            </div>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="radio" onClick={()=>setRating(1)}/>
                            <label className="form-check-label">Bad</label>
                            <div className="rating">
                                <i className="icon-Star"></i>
                            </div>
                        </div>
                    </form>
                    <label className="sm">Leave a feedback to enrich your experience</label>
                    <textarea className="form-control" rows="3" cols="45" name="remark" placeholder="Enter your feedback" onChange={event=>setFeedback(event.target.value)}></textarea>
                </div>
                <div style={{marginTop:'20px'}}>
                    <div className="btn btn-primary w-100" onClick={()=>postFeedback()}>Submit</div>
                </div>
            </div>    
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings, userDetails: state.loginReducer.authenticate.user }), null)(Review);
